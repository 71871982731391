import React, { CSSProperties, ComponentProps } from 'react'
import cn from 'classnames'

export const GridContainer = ({
  children,
  className,
  ...props
}: ComponentProps<'div'>) => (
  <div
    style={{ '--grid-min-width': '260px' } as CSSProperties}
    className={cn('responsive-grid gap-5', className)}
    {...props}
  >
    {children}
  </div>
)
