import React, { FC } from 'react'
import * as GridItem from '../item'
import { Clipboard } from '@phosphor-icons/react'
import { Checkin } from 'store/modules/checkins'

export const CheckinItem: FC<{ record: Checkin; storyItemId?: string }> = ({
  record,
  storyItemId,
}) => {
  if (!record.finalisedAt || !record.assessor) return null

  let participants = ''
  if (record.assessor.isCurrentUser && record.author.isCurrentUser)
    participants = `You`
  else if (record.assessor.isCurrentUser)
    participants = `You and ${record.author.fname}`
  else if (record.author.isCurrentUser)
    participants = `You and ${record.assessor.fname}`
  else participants = `${record.author.fname} and ${record.assessor.fname}`

  return (
    <GridItem.Root record={record} storyItemId={storyItemId}>
      <GridItem.Avatars
        from={record.author}
        to={record.assessor !== null ? [record.assessor] : undefined}
      />
      <GridItem.Body>
        {participants} completed a Check-in{' '}
        {record.positionName && `against ${record.positionName}`}
      </GridItem.Body>
      <GridItem.Meta>
        <GridItem.Type icon={Clipboard}>Check-in</GridItem.Type>
        <GridItem.Date date={record.finalisedAt} />
        <GridItem.Visibility visibility="reporting_line" />
      </GridItem.Meta>
    </GridItem.Root>
  )
}
