import React, { FC } from 'react'
import * as GridItem from '../item'
import { CircleWavyCheck } from '@phosphor-icons/react'
import { ReactionBar } from 'components/reaction-bar'
import { Win } from 'store/modules/wins'
import { Link } from 'src/design-system'
import { openModal } from 'app/packs/src/utils/open-modal'

export const WinItem: FC<{ record: Win; storyItemId?: string }> = ({
  record,
  storyItemId,
}) => (
  <GridItem.Root
    record={record}
    storyItemId={storyItemId}
    onClick={() => openModal(`/wins/${record.id}`)}
  >
    <GridItem.Avatars from={record.reporter} to={record.winners} />
    <GridItem.Body>{record.content}</GridItem.Body>
    <GridItem.Meta>
      <GridItem.Type icon={CircleWavyCheck}>Win</GridItem.Type>
      <GridItem.Date date={record.createdAt} />
      <GridItem.Visibility visibility={record.visibility} />
    </GridItem.Meta>
    <GridItem.Skills skills={record.skills} />
    <GridItem.Footer storyItemId={storyItemId}>
      <ReactionBar reactableId={record.id} reactableType="Win" />
    </GridItem.Footer>
  </GridItem.Root>
)
