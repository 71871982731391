import { Megaphone } from '@phosphor-icons/react'
import React, { FC } from 'react'
import * as GridItem from '../item'
import { FeedbackRequest as FeedbackRequestModel } from 'store/modules/feedback-request'

export const FeedbackRequest: FC<{
  record: FeedbackRequestModel
  storyItemId?: string
}> = ({ record, storyItemId }) => (
  <GridItem.Root record={record} storyItemId={storyItemId}>
    <GridItem.Avatars from={record.creator} to={record.users} />
    <GridItem.Body>{record.content}</GridItem.Body>
    <GridItem.Meta>
      <GridItem.Type icon={Megaphone}>Feedback Requested</GridItem.Type>
      <GridItem.Date date={record.createdAt} />
      <GridItem.Visibility visibility="reporting_line" />
    </GridItem.Meta>

    <GridItem.Footer storyItemId={storyItemId}>
      <GridItem.Skills skills={record.skills} />
    </GridItem.Footer>
  </GridItem.Root>
)
