import { Instance } from 'mobx-state-tree'
import { createContext, useContext } from 'react'
import { Action } from 'store/modules/actions'
import { FeedbackItem } from 'store/modules/feedback-item'
import { Win } from 'store/modules/wins'
import { WordFeedStoryMode } from '../../home-page/work-feed/work-feed.types'

export const allowedStoryUpdateTypes = [Win, FeedbackItem, Action] as const
export type AllowedStoryUpdateType = Instance<
  typeof allowedStoryUpdateTypes[number]
>
export const updateCanBeAddedToStory = (
  update: unknown
): update is AllowedStoryUpdateType =>
  allowedStoryUpdateTypes.some((type) => type.is(update))

type ActivityGridContext = {
  storyMode: WordFeedStoryMode
  addingToStoryId?: string
  toggleUpdateInStory: (update: AllowedStoryUpdateType) => void
  addedUpdates: AllowedStoryUpdateType[]
}

const ActivityGridContext = createContext({} as ActivityGridContext)

export const ActivityGridProvider = ActivityGridContext.Provider

export const useActivityGrid = () => useContext(ActivityGridContext)
