import React, { Fragment, useMemo } from 'react'
import { FC, ReactNode } from 'react'
import { User } from 'store/modules/users'
import { ActivityFeedAvatars } from '../activity-feed-header/activity-feed-avatars'
import { CheckSquare, Icon, Plus, Sparkle, Square } from '@phosphor-icons/react'
import { formatDate } from 'app/packs/src/utils/date-helpers'
import {
  Button,
  ConfirmationDialog,
  OverflowMenu,
  Tooltip,
} from 'src/design-system'
import { VisibilityIcon } from 'components/visibility-icon'
import { VisibilityStatus } from 'app/packs/src/types/entities'
import { Skill } from 'store/modules/skills'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { updateCanBeAddedToStory, useActivityGrid } from './context'
import cn from 'classnames'
import { Activity } from 'store/modules/activities'
import { successToast } from 'app/packs/src/utils/success-toast'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { observer } from 'mobx-react-lite'
import { store } from 'store/index'

export const Root: FC<{
  children: ReactNode
  record: Activity['record']
  storyItemId?: string
  onClick?: () => void
}> = observer(({ children, record, storyItemId, onClick }) => {
  const { storyMode, toggleUpdateInStory, addedUpdates } = useActivityGrid()

  const editingState = storyMode === 'editing' || storyMode === 'creating'

  const enabled = editingState && updateCanBeAddedToStory(record)
  const added = enabled && addedUpdates && addedUpdates.includes(record)
  const Icon = added ? CheckSquare : Square

  const storyItem = store.storyItems.byId(storyItemId)

  if (storyItem && storyItem.aasmState === 'rejected') return null

  return (
    <div
      className={cn(
        'rounded-lg border p-4 flex flex-col gap-3 relative transition-colors',
        editingState && 'cursor-pointer',
        added ? '!border-green-500' : 'border-gray-100'
      )}
      onClick={() => (enabled ? toggleUpdateInStory(record) : onClick?.())}
    >
      {enabled && (
        <div
          className={cn(
            'absolute top-0 right-0 pt-4 pr-4 transition-colors animate-fade-in',
            added && 'text-green-500'
          )}
        >
          <Icon size={20} />
        </div>
      )}

      {children}
    </div>
  )
})

export const Avatars: FC<{ from: User; to?: User[] }> = ({ from, to = [] }) => {
  return (
    <ActivityFeedAvatars
      creators={[from]}
      receivers={to}
      showReceivers={!!to.length}
    />
  )
}

export const Body: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <HtmlContent className="text-gray-800 mb-auto line-clamp-3">
      {children}
    </HtmlContent>
  )
}

export const Meta: FC<{ children: ReactNode[] }> = ({ children }) => (
  <div className="flex gap-2 text-gray-600 items-center">
    {children.map((child, i) => (
      <Fragment key={i}>
        {child}
        {i < children.length - 1 && (
          <span className="leading-none text-xs">•</span>
        )}
      </Fragment>
    ))}
  </div>
)

export const Type: FC<{ children: string; icon: Icon }> = ({
  children,
  icon: Icon,
}) => (
  <div className="flex items-center gap-1.5 text-xs leading-none">
    {children}
    <Icon weight="bold" />
  </div>
)

export const Date: FC<{ date: Date }> = ({ date }) => {
  const formattedDate = formatDate(date)
  return (
    <time dateTime={date.toISOString()} className="text-xs leading-none">
      {formattedDate}
    </time>
  )
}

export const Visibility: FC<{ visibility: VisibilityStatus }> = ({
  visibility,
}) => {
  const visibilityText = useMemo(() => {
    switch (visibility) {
      case 'only_me':
        return 'Only you'
      case 'reporting_line':
        return 'You and your reporting line'
      case 'org':
        return 'Everyone in your org'
    }
  }, [visibility])

  return (
    <Tooltip content={visibilityText}>
      <VisibilityIcon
        visibility={visibility}
        includeTag={false}
        className="h-3 w-3"
      />
    </Tooltip>
  )
}

export const Skills: FC<{ skills: Skill[] }> = ({ skills }) => {
  if (!skills.length) return null
  return (
    <div className="flex flex-wrap gap-2">
      {skills.map((skill) => (
        <span
          key={skill.id}
          className="text-xs text-green-700 bg-green-100 px-1.5 py-0.5 rounded-[4px]"
        >
          {skill.name}
        </span>
      ))}
    </div>
  )
}

export const Footer: FC<{ children: ReactNode; storyItemId?: string }> =
  observer(({ children, storyItemId }) => {
    const storyItem = store.storyItems.byId(storyItemId)

    const onApprove = React.useCallback(async () => {
      if (!storyItemId) return

      const result = await store.storyItems.update(storyItemId, {
        aasmState: 'approved',
      })

      if (result.success) {
        successToast('Added to story.')
      } else {
        errorToast('Sorry there was a problem adding to the story.')
      }
    }, [storyItemId])

    const onIgnore = React.useCallback(async () => {
      if (!storyItemId) return

      const result = await store.storyItems.update(storyItemId, {
        aasmState: 'rejected',
      })

      if (result.success) {
        successToast('Removed.')
      } else {
        errorToast('Sorry there was a problem ignoring this suggestion.')
      }
    }, [storyItemId])

    return (
      <div className="flex items-center justify-between empty:hidden">
        {storyItem && storyItem.aasmState === 'suggested' && (
          <>
            <span className="text-pink-700 text-xs">
              <Sparkle weight="fill" /> Suggested update
            </span>{' '}
            <div className="flex gap-x-2">
              <Button
                variant="outline"
                colourVariant="highlight"
                onClick={onIgnore}
              >
                Ignore
              </Button>
              <Button colourVariant="highlight" onClick={onApprove}>
                <Plus className="mt-0.5" />
                Add
              </Button>
            </div>
          </>
        )}
        {storyItem && storyItem.aasmState === 'approved' && (
          <>
            {children} <StoryItemOverflow storyItemId={storyItemId} />
          </>
        )}
      </div>
    )
  })

type StoryItemActionsProps = {
  storyItemId?: string
}

export const StoryItemOverflow = observer((props: StoryItemActionsProps) => {
  const { storyItemId } = props

  const onConfirm = React.useCallback(async () => {
    const storyItem = store.storyItems.byId(storyItemId)

    if (!storyItem) return

    const result = await storyItem.destroy()
    if (result?.success) {
      successToast('Removed from story.')
    } else {
      errorToast()
    }
  }, [storyItemId])

  return (
    <ConfirmationDialog.Root
      body="This will remove this update from the story."
      onConfirm={onConfirm}
      title="Are you sure?"
    >
      <OverflowMenu.Root className="h-4 w-4" right>
        <ConfirmationDialog.Trigger asChild>
          <OverflowMenu.Button variant={'destructive'}>
            Remove from story
          </OverflowMenu.Button>
        </ConfirmationDialog.Trigger>
      </OverflowMenu.Root>
    </ConfirmationDialog.Root>
  )
})
