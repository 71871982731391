import * as React from 'react'
import { Activity } from 'store/modules/activities'
import { WinItem } from './win-item'
import { ActionItem } from './action-item'
import { FeedbackItem } from './feedback-item'
import { CheckinItem } from './checkin-item'
import { FeedbackRequest } from './feedback-request'

export type ActivityItemProps = {
  activity: Activity
}

export const ActivityItem = (props: ActivityItemProps) => {
  const { activity } = props
  if (activity.recordType === 'Win') return <WinItem record={activity.record} />
  if (activity.recordType === 'Action')
    return <ActionItem record={activity.record} />
  if (activity.recordType === 'FeedbackItem')
    return <FeedbackItem record={activity.record} />
  if (activity.recordType === 'Checkin')
    return <CheckinItem record={activity.record} />
  if (activity.recordType === 'FeedbackRequest')
    return <FeedbackRequest record={activity.record} key={activity.id} />
  return null
}
