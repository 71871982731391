import { Checks } from '@phosphor-icons/react'
import React, { FC } from 'react'
import * as GridItem from '../item'
import { Action } from 'store/modules/actions'

export const ActionItem: FC<{ record: Action; storyItemId?: string }> = ({
  record,
  storyItemId,
}) => (
  <GridItem.Root record={record} storyItemId={storyItemId}>
    <GridItem.Avatars from={record.user} />
    <GridItem.Body>{record.content}</GridItem.Body>
    <GridItem.Meta>
      <GridItem.Type icon={Checks}>Completed Action</GridItem.Type>
      {record.completedAt && <GridItem.Date date={record.completedAt} />}
      <GridItem.Visibility visibility="reporting_line" />
    </GridItem.Meta>
    <GridItem.Footer storyItemId={storyItemId}>
      <GridItem.Skills skills={record.skills} />
    </GridItem.Footer>
  </GridItem.Root>
)
