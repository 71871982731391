import { Megaphone } from '@phosphor-icons/react'
import React, { FC } from 'react'
import * as GridItem from '../item'
import { FeedbackItem as FeedbackItemModel } from 'store/modules/feedback-item'

export const FeedbackItem: FC<{
  record: FeedbackItemModel
  storyItemId?: string
}> = ({ record, storyItemId }) => (
  <GridItem.Root record={record} storyItemId={storyItemId}>
    <GridItem.Avatars from={record.author} to={[record.receiver]} />
    <GridItem.Body>{record.content}</GridItem.Body>
    <GridItem.Meta>
      <GridItem.Type icon={Megaphone}>Feedback Received</GridItem.Type>
      <GridItem.Date date={record.createdAt} />
      <GridItem.Visibility visibility="reporting_line" />
    </GridItem.Meta>

    <GridItem.Footer storyItemId={storyItemId}>
      <GridItem.Skills skills={record.skills} />
    </GridItem.Footer>
  </GridItem.Root>
)
